@media print {
  @page {
    size: A4 portrait;
    margin-top: 1.75cm;
    margin-bottom: 1.75cm;
  }

  .print-exact-color {
    -webkit-print-color-adjust: exact !important;
  }

  .print-page-break {
    break-before: page;
  }

  .print-margin {
    margin-bottom: 0 !important;
  }
}

.print-cover-card {
  break-after: page;
  min-height: 1304px;
}

.print-card {
  min-width: 900px;
  min-height: 1377px;
}