.date-picker-year-max-width {
  width: 190px;

  .react-datepicker__year-wrapper {
    justify-content: center;
  }
}

.date-picker-month-max-width {
  .react-datepicker__month-container {
    width: 185px;
  }
}

.center-header-arrows {
  .react-datepicker__navigation-icon {
    top: 10%;
  }
}
