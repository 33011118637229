.preview-modal-width {
    min-width: 80%
}

.preview-modal {
    height: 95vh;
    overflow: hidden;
}

.preview-container {
    display: inline-block;
    overflow: hidden;
}

.preview-element {
    height: 80vh;
    display: inline-block;
    transform: scale(0.8);
    transform-origin: top left;
}